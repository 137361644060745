import * as React from 'react'
import { AccordionItem } from '@toasttab/buffet-pui-accordion'
import { Alert } from '@toasttab/buffet-pui-alerts'
import { Badge } from '@toasttab/buffet-pui-badge'
import { Button } from '@toasttab/buffet-pui-buttons'

interface WarningNotesProps {
  hasDuplicates?: boolean
  onSeeAllDuplicates?(): void
}

export function WarningNotes({
  hasDuplicates,
  onSeeAllDuplicates
}: WarningNotesProps) {
  const shouldShowWarning = hasDuplicates

  if (!shouldShowWarning) {
    return null
  }

  return (
    <div className='grid grid-cols-1 gap-2'>
      <Alert variant='warning'>
        Some modules in the below list are in an unhealthy situation! Please
        read below notes if you own them.
      </Alert>
      {hasDuplicates && (
        <AccordionItem
          id='duplicate'
          title='Duplicate entries in the import map'
        >
          <div className='flex flex-col items-end gap-2'>
            <p>
              {/* Should badge be a span? */}
              Modules marked with the <Badge color='warning'>
                Duplicate
              </Badge>{' '}
              have been found in multiple import maps, which can happen if they
              are served by both SPA Control and Service Management. To solve
              this, please remove them from the legacy manifests, explained in
              the{' '}
              <a
                href='https://toasttab.atlassian.net/wiki/spaces/FE/pages/2905243694/How+to+migrate+a+SPA+to+SPA+control#Cleanup'
                rel='noopener noreferrer'
                className='inline-link'
              >
                How to migrate a SPA to SPA Control
              </a>{' '}
              document.
            </p>
            <Button onClick={onSeeAllDuplicates} variant='link'>
              See all duplicated entries
            </Button>
          </div>
        </AccordionItem>
      )}
    </div>
  )
}
