import * as React from 'react'
import {
  addOverride,
  disableOverride,
  enableOverride,
  getAllModules,
  getDirtyOverrides,
  isAnyLocalhostOverrideActive,
  removeAutoDevLibOverrides,
  getCachedDuplicateEntries,
  removeOverride,
  resetOverrides,
  sortByState,
  ToastImport,
  transformToastImportMap,
  addDevLibOverrides
} from './importMapApi'
import { usePreferences } from '@local/preferences-tool'

export interface UseImportMapResult {
  data: ToastImport[] | undefined
  loading: boolean
  mutations: {
    addOverride: (key: string, value: string) => void
    enableOverride: (key: string) => void
    disableOverride: (key: string) => void
    removeOverride: (key: string) => void
    toggleOpen: (key: string) => void
    resetOverrides: () => void
  }
}

export const useImportMap = (): UseImportMapResult => {
  const [open, setOpen] = React.useState<string[]>([])
  const [modules, setModules] = React.useState<ToastImport[] | undefined>()
  const [{ devLibOverride }] = usePreferences()

  const handleAutoDevLibOverride = () => {
    if (
      devLibOverride === 'always' ||
      (devLibOverride === 'auto' && isAnyLocalhostOverrideActive())
    ) {
      addDevLibOverrides()
    } else {
      removeAutoDevLibOverrides()
    }
  }

  React.useEffect(() => {
    handleAutoDevLibOverride()
  }, [devLibOverride])

  // We could use react-query if we want so
  React.useEffect(() => {
    Promise.all([
      getAllModules(),
      getDirtyOverrides(),
      getCachedDuplicateEntries()
    ]).then(([modules, dirtyOverrides, duplicates]) => {
      setModules(
        transformToastImportMap(modules, dirtyOverrides, open, duplicates).sort(
          sortByState
        )
      )
    })
  }, [open, devLibOverride])

  const close = (key: string) => {
    setOpen(arrayRemove(open, key))
  }

  return {
    data: modules,
    loading: modules === undefined,
    mutations: {
      toggleOpen(key: string) {
        if (open.includes(key)) {
          setOpen(arrayRemove(open, key))
        } else {
          setOpen(arrayAdd(open, key))
        }
      },
      addOverride(key: string, value: string) {
        addOverride(key, value)
        close(key)
        handleAutoDevLibOverride()
      },
      removeOverride(key: string) {
        removeOverride(key)
        close(key)
        handleAutoDevLibOverride()
      },
      disableOverride(key: string) {
        disableOverride(key)
        close(key)
        handleAutoDevLibOverride()
      },
      enableOverride(key: string) {
        enableOverride(key)
        close(key)
        handleAutoDevLibOverride()
      },
      resetOverrides() {
        resetOverrides()
        setOpen([])
        handleAutoDevLibOverride()
      }
    }
  }
}

function arrayRemove<T>(arr: Array<T>, value: T) {
  return arr.filter((ele) => ele !== value)
}

function arrayAdd<T>(arr: Array<T>, value: T) {
  return arr.concat([value])
}
